import { useUser } from "@/hooks/use-user";
import { Redirect } from "wouter";

interface RequireClientProps {
  children: React.ReactNode;
}

export function RequireClient({ children }: RequireClientProps) {
  const { user } = useUser();

  if (!user || user.role !== "client") {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
} 