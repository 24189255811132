import { useState } from "react";
import { useLocation, useRoute } from "wouter";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { toast } from "@/components/ui/use-toast";
import { Loader2 } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";

export default function GoogleRoleSelectionPage() {
  const [, setLocation] = useLocation();
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState<"client" | "cleaner" | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  const handleRoleSelection = async () => {
    if (!selectedRole) return;
    
    console.log('Starting role selection process for role:', selectedRole);
    setIsSubmitting(true);
    try {
      console.log('Sending role selection request to server...');
      const response = await fetch('/api/auth/select-role', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ role: selectedRole }),
      });
      const data = await response.json();
      console.log('Received response from server:', data);

      if (response.ok) {
        console.log('Role selection successful, got user data:', data.user);
        console.log('Redirecting to:', data.redirectTo);
        
        // Force a full page reload to ensure we get fresh data
        window.location.href = data.redirectTo;
        return;
      } else {
        toast({
          variant: "destructive",
          title: t('auth.error'),
          description: data.message || t('auth.roleSelectionError'),
        });
      }
    } catch (error) {
      console.error('Error during role selection:', error);
      toast({
        variant: "destructive",
        title: t('auth.error'),
        description: t('auth.roleSelectionError'),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-primary/10 to-primary/5 py-12 px-4">
      <div className="w-full max-w-md">
        <Card>
          <CardHeader>
            <CardTitle>{t('auth.selectRoleTitle')}</CardTitle>
            <CardDescription>
              {t('auth.selectRoleDescription')}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex flex-col items-center">
                <img 
                  src="/images/home-owners.png" 
                  alt="Home Owner" 
                  className="w-full max-w-[280px] h-auto object-contain mb-4" 
                />
                <div 
                  onClick={() => setSelectedRole("client")}
                  className={`w-full px-6 py-4 rounded-sm border cursor-pointer flex items-center ${
                    selectedRole === "client" 
                      ? "border-2 border-primary bg-primary/5 text-primary font-medium" 
                      : "border-input hover:border-primary/50"
                  }`}
                >
                  <div className={`mr-3 h-4 w-4 rounded-full border ${
                    selectedRole === "client" 
                      ? "border-primary bg-primary" 
                      : "border-gray-400"
                  }`} />
                  <span className="text-left text-base whitespace-normal">
                    {t('auth.roleClient')}
                  </span>
                </div>
              </div>
              
              <div className="flex flex-col items-center">
                <img 
                  src="/images/home-cleaner.png" 
                  alt="Home Cleaner" 
                  className="w-full max-w-[280px] h-auto object-contain mb-4" 
                />
                <div 
                  onClick={() => setSelectedRole("cleaner")}
                  className={`w-full px-6 py-4 rounded-sm border cursor-pointer flex items-center ${
                    selectedRole === "cleaner" 
                      ? "border-2 border-primary bg-primary/5 text-primary font-medium" 
                      : "border-input hover:border-primary/50"
                  }`}
                >
                  <div className={`mr-3 h-4 w-4 rounded-full border ${
                    selectedRole === "cleaner" 
                      ? "border-primary bg-primary" 
                      : "border-gray-400"
                  }`} />
                  <span className="text-left text-base whitespace-normal">
                    {t('auth.roleCleaner')}
                  </span>
                </div>
              </div>

              <div className="text-sm text-muted-foreground mt-6">
                {t('auth.termsNotice')}{' '}
                <Button variant="link" asChild className="p-0 h-auto text-sm">
                  <a href="/terms">
                    {t('auth.termsAndConditions')}
                  </a>
                </Button>
              </div>

              <Button
                onClick={handleRoleSelection}
                className="w-full mt-6"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                {t('auth.continue')}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
} 