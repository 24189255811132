/**
 * CSRF protection has been removed from the application.
 * This is a stub implementation to maintain compatibility with existing code.
 */

/**
 * Returns an empty string - CSRF protection is disabled
 */
export function extractCsrfToken(): string | null {
  return null;
}

/**
 * Returns an empty string - CSRF protection is disabled
 */
export async function getCsrfToken(): Promise<string> {
  return "";
}

/**
 * No-op function - CSRF protection is disabled
 */
export function clearCsrfToken(): void {
  // No operation needed
}
