import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { User } from "@db/schema";
import { useToast } from "@/hooks/use-toast";
import { getCsrfToken, clearCsrfToken } from "@/lib/csrf";

export function useUser() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const {
    data: user,
    error,
    isLoading,
  } = useQuery<User | null>({
    queryKey: ["/api/user"],
    queryFn: async () => {
      const res = await fetch("/api/users/me", {
        credentials: "include",
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      });
      if (!res.ok) {
        if (res.status === 401) return null;
        throw new Error(await res.text());
      }
      return res.json();
    },
    retry: false,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  const updateUserMutation = useMutation({
    mutationFn: async (userData: Partial<User>) => {
      const csrfToken = await getCsrfToken();
      const res = await fetch("/api/users/me", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify(userData),
        credentials: "include",
      });

      if (!res.ok) {
        const contentType = res.headers.get("content-type");
        let errorMessage: string;

        if (contentType && contentType.includes("application/json")) {
          const errorData = await res.json();
          errorMessage =
            errorData.message || errorData.error || "Failed to update profile";
        } else {
          // If not JSON, get the text and provide a more generic error
          const errorText = await res.text();
          console.error("Server error response:", errorText);
          errorMessage = "Server error occurred. Please try again later.";
        }

        throw new Error(errorMessage);
      }

      return res.json();
    },
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(["/api/user"], updatedUser);
      toast({
        title: "Profile updated",
        description: "Your profile has been updated successfully.",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description:
          error instanceof Error
            ? error.message
            : "Failed to update profile. Please try again.",
        variant: "destructive",
      });
    },
  });

  const loginMutation = useMutation({
    mutationFn: async (params: {
      userData: Partial<User>;
      headers?: HeadersInit;
    }) => {
      const res = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(params.headers || {}),
        },
        body: JSON.stringify(params.userData),
        credentials: "include",
      });

      if (!res.ok) {
        const errorText = await res.text();
        try {
          const errorJson = JSON.parse(errorText);
          throw new Error(errorJson.message || errorText);
        } catch {
          throw new Error(errorText);
        }
      }
      return res.json();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["/api/user"], data);
      toast({
        title: "Success",
        description: "Logged in successfully",
      });
    },
    onError: (error: Error) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to login",
      });
    },
  });

  const registerMutation = useMutation({
    mutationFn: async (params: {
      userData: Partial<User>;
      headers?: HeadersInit;
    }) => {
      const csrfToken = await getCsrfToken();
      const res = await fetch("/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
          ...(params.headers || {}),
        },
        body: JSON.stringify(params.userData),
        credentials: "include",
      });

      if (!res.ok) {
        const errorText = await res.text();
        try {
          const errorJson = JSON.parse(errorText);
          throw new Error(errorJson.message || errorText);
        } catch {
          throw new Error(errorText);
        }
      }
      return res.json();
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["/api/user"], data);
      toast({
        title: "Success",
        description: "Registration successful",
      });
    },
    onError: (error: Error) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to register",
      });
    },
  });

  const logoutMutation = useMutation({
    mutationFn: async () => {
      const csrfToken = await getCsrfToken();
      const res = await fetch("/api/logout", {
        method: "POST",
        credentials: "include",
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      });
      if (!res.ok) throw new Error(await res.text());
      clearCsrfToken(); // Clear the cached token after logout
      return res.json();
    },
    onSuccess: () => {
      queryClient.setQueryData(["/api/user"], null);
      toast({
        title: "Success",
        description: "Logged out successfully",
      });
    },
  });

  return {
    user,
    isLoading,
    error,
    updateUser: (userData: Partial<User>) =>
      updateUserMutation.mutateAsync(userData),
    login: async (userData: Partial<User>, headers?: HeadersInit) =>
      loginMutation.mutateAsync({ userData, headers }),
    register: async (userData: Partial<User>, headers?: HeadersInit) =>
      registerMutation.mutateAsync({ userData, headers }),
    logout: logoutMutation.mutateAsync,
  };
}
