import React, { useState, useEffect } from 'react';
import { Sun, Moon, Palette } from 'lucide-react';

type Theme = 'default' | 'bright' | 'dark';

const ThemeToggle: React.FC = () => {
  const [theme, setTheme] = useState<Theme>('default');

  // Load theme from localStorage on component mount
  useEffect(() => {
    const savedTheme = localStorage.getItem('weklean-theme') as Theme | null;
    if (savedTheme) {
      setTheme(savedTheme);
      applyTheme(savedTheme);
    }
  }, []);

  // Apply theme changes
  const applyTheme = (newTheme: Theme) => {
    const root = document.documentElement;
    
    // Remove all theme classes first
    root.classList.remove('bright-theme', 'dark');
    
    // Apply the selected theme
    if (newTheme === 'bright') {
      root.classList.add('bright-theme');
    } else if (newTheme === 'dark') {
      root.classList.add('dark');
    }
    
    // Save to localStorage
    localStorage.setItem('weklean-theme', newTheme);
    
    // Force a re-render of the page to ensure all styles are applied
    document.body.style.display = 'none';
    setTimeout(() => {
      document.body.style.display = '';
    }, 10);
  };

  // Handle theme change
  const handleThemeChange = (newTheme: Theme) => {
    setTheme(newTheme);
    applyTheme(newTheme);
  };

  return (
    <div className="flex items-center space-x-2 bg-background border rounded-lg p-1">
      <button
        onClick={() => handleThemeChange('default')}
        className={`p-1.5 rounded-md transition-colors ${
          theme === 'default' ? 'bg-primary text-white' : 'text-muted-foreground hover:bg-muted'
        }`}
        title="Default Theme"
      >
        <Sun size={18} />
      </button>
      
      <button
        onClick={() => handleThemeChange('bright')}
        className={`p-1.5 rounded-md transition-colors ${
          theme === 'bright' ? 'bg-primary text-white' : 'text-muted-foreground hover:bg-muted'
        }`}
        title="Bright Theme"
      >
        <Palette size={18} />
      </button>
      
      <button
        onClick={() => handleThemeChange('dark')}
        className={`p-1.5 rounded-md transition-colors ${
          theme === 'dark' ? 'bg-primary text-white' : 'text-muted-foreground hover:bg-muted'
        }`}
        title="Dark Theme"
      >
        <Moon size={18} />
      </button>
    </div>
  );
};

export default ThemeToggle; 