/**
 * Utility functions for navigation
 */

/**
 * Stores the current page URL in sessionStorage before navigating to the login page.
 * This allows redirecting back to the original page after successful login.
 *
 * @param currentPath - The current path to store
 */
export const storePreviousPageBeforeLogin = (currentPath: string): void => {
  // Don't store login or auth pages as the previous page
  if (
    currentPath !== "/login" &&
    currentPath !== "/auth" &&
    !currentPath.startsWith("/reset-password")
  ) {
    sessionStorage.setItem("previousPage", currentPath);
  }
};

/**
 * Gets the stored previous page URL from sessionStorage.
 *
 * @returns The stored previous page URL or '/' if none exists
 */
export const getPreviousPage = (): string => {
  return sessionStorage.getItem("previousPage") || "/";
};

/**
 * Clears the stored previous page URL from sessionStorage.
 */
export const clearPreviousPage = (): void => {
  sessionStorage.removeItem("previousPage");
};
